
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { Options, Vue, prop } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { PickupManifestController } from "@/app/ui/controllers/PickupManifestController";

class Props {
  options = prop<any[]>({
    default: [],
    type: Array
  });
  modelValue = prop<any>({
    required: true
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
  isShowErrorMessage = prop<boolean>({
    default: true,
    type: Boolean
  });
  isShowIconEmpty = prop<boolean>({
    default: false,
    type: Boolean
  });
  isShowErrorIndicator = prop<boolean>({
    default: false,
    type: Boolean
  });
  placeholder = prop<string>({
    default: "",
    type: String
  });
  keyName = prop<string>({
    default: "name",
    type: String
  });
  keyValue = prop<string>({
    default: "value",
    type: String
  });
  longTrim = prop<number>({
    default: 50,
    type: Number
  });
  secondKeyName = prop<string>({
    default: "",
    type: String
  });
  addDriver = prop<boolean>({
    default: false,
    type: Boolean
  });
  addLicensePlate = prop<boolean>({
    default: false,
    type: Boolean
  });
  serverSide = prop<boolean>({
    default: true,
    type: Boolean
  });
  errorCaption = prop<string>({
    default: "",
    type: String
  });
  hideErrorIcon = prop<boolean>({
    default: false,
    type: Boolean
  });
  addAccountNumber = prop<boolean>({
    default: false,
    type: Boolean
  });
  titleInside = prop<string>({
    default: "",
    type: String
  });
  openIcon = prop<string>({
    default: "chevron-up",
    type: String
  });
  closeIcon = prop<string>({
    default: "chevron-down",
    type: String
  });
  allEnabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  isFirstInput = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorMessage = prop<string>({
    default: "Apa yang Anda cari tidak ditemukan atau tidak ada.",
    type: String
  });
  customWidth = prop<string>({
    default: "w-full",
    type: String
  });
}

@Options({
  emits: [
    "change",
    "update:modelValue",
    "focus",
    "blur",
    "filter",
    "close",
    "chevron",
    "submit:driver",
    "submit:licensePlate"
  ],
  components: {
    Skeleton
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  },
  watch: {
    modelValue() {
      this.filterSearch = this.modelValue[this.keyName] || this.modelValue;
    }
  }
})
export default class SelectSearch extends Vue.with(Props) {
  mounted() {
    if (this.isFirstInput) {
      const pseudoInput: any = this.$refs.pseudoInput;
      pseudoInput?.focus();
    }
  }

  // logic open close menu
  isChevron = false;
  isOpenMenu = false;
  onFilter = false;
  onOpenSelect(value: boolean) {
    if (!value) {
      this.$emit("close", "");
      this.$emit("blur", "");
    }
    this.isOpenMenu = value;
  }
  closeSelect() {
    this.onOpenSelect(false);
    this.$emit("blur", "");
    if (!this.filterSearch) {
      this.$emit("update:modelValue", "");
      this.$emit("change", "");
      this.filterSearch = "";
    } else this.filterSearch = this.modelValue[this.keyName] || this.modelValue;
  }
  clearSelect() {
    this.$emit("update:modelValue", "");
  }
  //logic filter search
  filterSearch =
    typeof this.modelValue === "object"
      ? this.modelValue[this.keyName]
      : this.modelValue;
  get dataOptions() {
    this.selectedIndex = this.options.findIndex((item: any) =>
      this.isSelected(item)
    );
    if (this.serverSide || !this.onFilter) {
      return this.options;
    }
    const tempOptions = this.options.filter((option: any) => {
      return option[this.keyName]
        ? option[this.keyName]
            .toLowerCase()
            .includes(this.filterSearch.toLowerCase())
        : option.toLowerCase().includes(this.filterSearch);
    });
    if (this.allEnabled && !this.filterSearch) {
      const allObject: any = {};
      allObject[this.keyName] = "All";
      allObject[this.keyValue] = "all";
      tempOptions.unshift(allObject);
    }
    this.selectedIndex = tempOptions.findIndex((item: any) =>
      this.isSelected(item)
    );
    return tempOptions;
  }
  onSearch(e: any) {
    this.filterSearch = e.target.value;
    this.onFilter = true;
    this.$emit("filter", e.target.value);
    this.isOpenMenu = true;
  }
  onFocus() {
    if (!this.isDisabled) {
      this.onOpenSelect(true);
      this.$emit("focus", "");
      this.openPlus = false;
      this.onFilter = false;
      this.driver = {
        name: "",
        phone: ""
      };
      this.licensePlate = "";
    }
  }

  // logic for selected item
  isSelected(item: any) {
    return this.modelValue?.[this.keyValue]
      ? this.modelValue?.[this.keyValue] === item?.[this.keyValue]
      : this.modelValue === item || this.modelValue === item?.[this.keyValue] || this.modelValue?.[this.keyValue] === item?.[this.keyValue];
  }
  onChooseSelect(item: any) {
    if (this.isSelected(item)) {
      this.$emit("update:modelValue", "");
      this.$emit("change", "");
      this.filterSearch = "";
    } else {
      this.$emit("update:modelValue", item);
      this.$emit("change", item);
      this.filterSearch = item[this.keyName] || item;
    }
    this.onOpenSelect(false);
  }
  ellipsisValue(value: string) {
    return ellipsisString(value, this.longTrim);
  }

  // handle add driver and add license plate
  openPlus = false;
  driver = {
    name: "",
    phone: ""
  };
  licensePlate = "";
  handleOpenPlus(value: boolean) {
    this.openPlus = value;
  }
  clearDriverForm() {
    this.driver.name = "";
    this.driver.phone = "";
  }
  clearLicensePlateForm() {
    this.licensePlate = "";
  }
  async submitDriver() {
    if (this.driver.name && this.driver.phone) {
      const resp = await PickupManifestController.addDriver(this.driver);
      if (resp) {
        this.$emit("submit:driver", this.driver);
        this.clearDriverForm();
        this.handleOpenPlus(false);
      }
    }
  }
  submitLicensePlate() {
    if (this.licensePlate) {
      this.$emit("submit:licensePlate", this.licensePlate);
      this.clearLicensePlateForm();
      this.handleOpenPlus(false);
    }
  }
  get isError() {
    return PickupManifestController.errorModal;
  }
  get errorCause() {
    return PickupManifestController.errorCause;
  }
  handleErrorClose() {
    PickupManifestController.setErrorModal(false);
  }
  filterInputDriverPhone(e: any) {
    e.target.value = e.target.value.replace(/\D+/g, "");
  }
  filterInputDriverName(e: any) {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
  }
  filterInputLicensePlate(e: any) {
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
  }

  handleOpenEditBeneficiaryAccount() {
    this.$router.push("/cod-income/beneficiary-account");
  }

  selectedIndex = -1;
  onKeyUpAndDown(e: any) {
    if (e.key === "ArrowDown") {
      this.selectedIndex += 1;
    }
    if (e.key === "ArrowUp") {
      this.selectedIndex -= 1;
    }
    if (this.selectedIndex === this.dataOptions?.length) {
      this.selectedIndex = 0;
    }
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.dataOptions?.length - 1;
    }
    if (!this.dataOptions[this.selectedIndex]) {
      return;
    }
    this.onChoose(this.dataOptions[this.selectedIndex]);
    const container: any = this.$refs.container;
    if (container) {
      const option: any = this.$refs[`option${this.selectedIndex}`];
      if (container.scroll) {
        container.scroll({
          top: option.offsetTop - option.offsetHeight
        });
      }
    }
  }
  onKeyDown(e: any) {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      this.onKeyUpAndDown(e);
    }
    if (e.key === "Tab") {
      this.closeSelect();
    }
    if (e.key === "Enter") {
      this.closeSelect();
      const inputRef: any = this.$refs.selectSearch;
      inputRef.blur();
    }
  }
  onChoose(item: any) {
    if (this.isSelected(item)) {
      this.$emit("update:modelValue", "");
      this.$emit("change", "");
      this.filterSearch = "";
    } else {
      this.$emit("update:modelValue", item);
      this.$emit("change", item);
      this.filterSearch = item[this.keyName] || item;
    }
  }

  focus() {
    const selectSearch: any = this.$refs.selectSearch;
    selectSearch?.focus();
  }
}
